import React from 'react';
import { Box, Text, Heading, Container, List,Link , ListItem, ListIcon, OrderedList, Flex } from '@chakra-ui/react';
import { FaCheckCircle } from 'react-icons/fa';

const TermsPage = () => {
  return (
    <Container maxW="container.lg" py={6}>
      <Heading as="h1" size="xl" mb={4}>
        Terms of Service
      </Heading>
      <Text fontSize="sm" color="gray.500" mb={4}>
        Effective Date: 26 February, 2025
      </Text>

      <Box>
        <Heading as="h2" size="lg" mt={6} mb={3}>
          Introduction
        </Heading>
        <Text>
        These Terms of Service are a legal binding agreement between you or on behalf of an entity (you) and Aquilis LLC and its affiliates and subsidiaries (“We”, “Company”, “Us” or “Our”) regarding accessing and using <Link to={"/"} fontWeight="semibold"><b>https://www.aquilis.co/ </b></Link> website. You agree that by using and accessing websites, you have read, understood and accepted to be bound by all Terms of Service. IF YOU DO NOT AGREE WITH ALL THE TERMS OF SERVICE, THEN YOU ARE NOT ALLOWED TO USE THE WEBSITE AND MUST DISCONTINUE THE USE. 
        </Text>
        <Text my={3}>
        Additional terms and conditions or documents posted on websites from time to time are incorporated by reference. We have the right to update and change the terms and conditions for any reason and any time. We will notify you about any changes by updating the date of the “Effective date” in Terms of Service. You have the right to receive specific notice for such updates. It is your duty to review these Terms of Service and stay updated regarding changes. By continuing to use the website after update in Terms and Service, you will be considered to have been made aware and accepted the changes.
        </Text>

        <Text my={3}>
        The information presented on this website is not intended for distribution or use by any person or entity in any jurisdiction or country where such distribution is prohibited by law, regulation or we require to register within such country or jurisdiction. As a result, someone who is using or visiting our website from other locations do so on their own initiative and are completely responsible for complying with laws, to the extent they apply. 
        </Text>

        <Text my={3}>
        The website is designed for users over the age of 18. All the users who are minor or below the age of 18 must have permission and are supervised by their parent or guardian to use the website. If you are minor, you must have your parent or guardian read Terms of Service before using the website. 
        </Text>
        <Heading as="h2" size="lg" mt={6} mb={3}>
          Intellectual Property Rights
        </Heading>
        <Text>
        Unless otherwise noted, the website is our proprietary  property and its all databases, source code, software, functionality, website designs, videos, audios, articles, photographs, text and graphics as well as its trademarks, service marks and logos are owned, licensed and controlled by us and are protected by international copyright law,international conventions and various other intellectual property rights and unfair competition laws in the United states. The website and its content or marks should not be reproduced, republished, aggregated,reposted, uploaded, publicly displayed, translated, encoded, transmitted, distributed, licensed, sold, or used for any commercial purpose without our prior written consent, unless its permitted in Terms of Service.
        </Text>
        <Text my={3}>
        If you are eligible to use the website, you are granted a limited license to access and use the website, as well as download or print any portion of the content which you have gained access for solely personal use and not commercial use. We reserve all rights to not grant to you in and to the website, content and marks. 
        </Text>
        <Heading as="h2" size="lg" mt={6} mb={3}>
          User Representations
        </Heading>
        <Text>
        When you visit the website, you represent and warrant that: 
        <OrderedList spacing={3} textAlign="start" py={6} px={10}>
              <ListItem>
                You have legal capacity and you agree to comply with the Terms of Service.
              </ListItem>
              <ListItem>
                You are not under the age of 18.
              </ListItem>
              <ListItem>
                You are not a minor in the jurisdiction in which you reside or if you are a minor then you have permission from your parents. 
              </ListItem>
              <ListItem>
                You would not access the website through automated or non-human means, whether through bot, script or otherwise.
              </ListItem>
              <ListItem>
                You will not use the website for any illegal purpose.
              </ListItem>
              
            </OrderedList>

        </Text>

        <Heading as="h2" size="lg" mt={6} mb={3}>
          Prohibited Activities
        </Heading>
        <Text my={3}>
        You are not allowed to access or use this website for any other purpose other than those for which it is made available. The website may not be used for any commercial purpose unless it is sponsored or permitted by us.        
        </Text>
        <Text my={3}>
            <OrderedList spacing={3} textAlign="start"  py={6} px={10}>
                <ListItem>Trick or mislead us or other users.</ListItem>
                <ListItem>Circumvent, disable or otherwise interfere with website security mechanisms such as copying of any content or impose restrictions on the use of the website to its content.</ListItem>
                <ListItem>Harm our website in any way</ListItem>
                <ListItem>Use any website in order to abuse, harass or harm any other person.</ListItem>  
                <ListItem>Use websites in any manner which is not aligned with laws or regulations.</ListItem>
                <ListItem>Submit false reports of abuse or misconduct.</ListItem>
                <ListItem>Use our website to sell or advertise goods and services.</ListItem> 
                <ListItem>Involve in unauthorised linking or farming on the website.</ListItem> 
                <ListItem>Upload or transmit (or attempt to upload or transmit) Trojan horses, viruses r any other material including capital letters or spamming that interfere with any party’s uninterrupted use and enjoyment of the website or modifies, alters or interferes with website’s use, features, functions, operation or maintenance.</ListItem> 
                <ListItem> Engage in any automated usage of the system, such as sending comments or messages via scripts, or utilise data mining, robots or other data collections and extraction techniques. </ListItem>
                <ListItem>Remove copyright or other proprietary rights notice from any content.</ListItem>
                <ListItem>Harass, annoy, intimidate or threaten any of our employees or independent contractors engaged in providing any portion of the website to you.</ListItem> 
                <ListItem>Interfere or create undue burden on a website or network or services connected to the website.</ListItem>
                <ListItem>Try to get beyond any restrictions in place by website to limit the access of the website or any part of the website.</ListItem> 
                <ListItem>Attempt to copy website’s software such as HTML, PHP, Flash, Javascript and other programming.</ListItem>
                <ListItem>Decipher, decompile, disassemble, or reserve engineer any software that is a component of the website or that is used to create it.</ListItem> 
                <ListItem>Use, launch, develop, or distribute any automated system, including but not limited to any spider, robot, cheat utility, scraper or offline reader that access this website or use or launch any unauthorised script or other software, unless it may be the consequence of the norm use of a search engine or internet browser.</ListItem> 
                <ListItem>Attempt to remove any content's copyright or other intellectual right notice. Taking advantage of a different username or adapting the identity of an additional user . Any data operates as either a proactive or passive means of collecting or sending information, may be published or transferred, or tried to become published or passed on. Example of such content involve graphic interchange formats (" Gifs", 1x1 pixels, web bugs, cookies and other similar devices (sometimes called "spyware" or "passive collect")</ListItem>
                <ListItem>Make any unauthorized use of the website by electronic or other means for the purpose of ending unsolicited email, by automated means or under false pretences. </ListItem>
                <ListItem>Use websites to compete with us or use its content for any revenue generating or commercial activity.</ListItem> 
            </OrderedList>
        </Text>

        <Heading as="h2" size="lg" mt={6} mb={3}>
            Website Management
        </Heading>
        <Text my={3}>
            We reserve the right, but not the obligation, to:        
        </Text>
        <Text my={3}>
            <OrderedList spacing={3} textAlign="start"  py={6} px={10}>
                <ListItem>Keep an eye out for any violations of these Terms of Service of the website.</ListItem>
                <ListItem>Take appropriate legal action against anyone who, in our sole discretion, violates law or these Terms of Service including, but not limited to, reporting such a user to law enforcement authorities.</ListItem>
                <ListItem>Remove from the website or otherwise disable any files and content that are excessive in size or that are burdensome to our systems in any way.</ListItem>
                <ListItem>Manage our website in a way that protects our property and protects our rights and ensures the smooth operation of the website.</ListItem>  
            </OrderedList>
        </Text>

        <Heading as="h2" size="lg" mt={6} mb={3}>
            Privacy Policy        
        </Heading>
        <Text my={3}>
            We care about data privacy and security. Please review our Privacy Policy.        
        </Text>

        <Heading as="h2" size="lg" mt={6} mb={3}>
            Term and Termination
        </Heading>
        <Text my={3}>
            These Terms of Service will remain in full force and effect while you visit the website. Without limiting any other provision of these Terms of Service, we reserve the right in our sole discretion and without notice or liability, to deny access to and use of website (including blocking certain IP addresses) to any person for any or no reason, including without limitation for breach of any representation, warranty or covenant contained in these services of any applicable law or regulation. We may terminate your use or participation in the website without notice, at our sole discretion.         
        </Text>


        <Heading as="h2" size="lg" mt={6} mb={3}>
            Modifications and Interruptions
        </Heading>
        <Text>
            We reserve a right to edit, modify, or remove the website’s content at any time and for any cause, without prior warning. However we have no responsibility to update any part of our website. We also reserve the right to change any part of the website without prior notice at any time. We will  not be liable to any third party for any modification , suspension or discontinuance of the website. We can not guarantee that the website will be available at all times. We might face software, hardware or other issues as well as the need to perform the website maintenance which can result in delays, interruptions or errors. We reserve the right to modify, change, update, revise, suspend or cease the website at any time for any reason or no reason without providing notice. You agree we have no liability whatsoever for any loss, damage,or inconvenience caused by your inability to access or use the website during downtime. Nothing in these Terms of Service will be construed to obligate us to maintain and support the Website or to supply any corrections, updates, or releases in connection therewith.
        </Text>

        <Heading as="h2" size="lg" mt={6} mb={3}>
          Governing Law
        </Heading>
        <Text>
            The use of this website and Terms of service are governed by and constructed in accordance with the laws of the United State of Wyoming. 
        </Text>

        <Heading as="h2" size="lg" mt={6} mb={3}>
        Corrections
        </Heading>
        <Text>
        There may be typographical errors, inaccuracies or omission including pricing, descriptions, pricing, availability and various other information on the website. We reserve the rights to correct the errors and update information on the website at any time without prior notice. 
        </Text>


        <Heading as="h2" size="lg" mt={6} mb={3}>
          Disclaimer
        </Heading>
        <Text>
        The website is provided as is and as available.  You agree that using websites and services is your sole risk. To the extent permitted by law, we disclaim all warranties, express or implied in connection with the website and your use of it. We make no warranties or representations about the accuracy or completeness of the website’s content or the content of any websites linked to the website and we will take no responsibility for any (1) errors, mistakes,or inaccuracy of content present on the website, (2) Any damage whether personal or property damage of any nature resulted from accessing the website, (3) Any unauthorised access of the secure servers or any information related financial or personal stored with in it, (4) Any interruption or halting of our transmission from the website, (5) Any bugs, viruses, trojan horses or anything like that may be transmitted through website by any third party, (6) Any errors or omissions in any content or material for any loss or damage of any kind incurred as a result of the use of any content posted, transmitted or otherwise made available via website.
        </Text>

        <Heading as="h2" size="lg" mt={6} mb={3}>
            Limitations of Liability        
        </Heading>
        <Text>
            Even if have been informed of the possibility of such damages, we,our directors, employees,independent contractors or agents will never be liable to you or third party for any direct, indirect, consequential, exclusionary, incidental, special or punitive damages, including lost profit, lost revenue, data loss or other damages resulting from your use of website.
        </Text>

        <Heading as="h2" size="lg" mt={6} mb={3}>
            Indemnification
        </Heading>
        <Text>
            By using the website,, you agree to defend, indemnify, and hold us, our subsidiaries, affiliates and each of our officers, agents, partners, employees, and independent contractors harmless from and against any loss, damage, liability, claim or demand, including reasonable legal fees and expenses made by any third party, any overtly damaging act directed at any other user of the website that you connected through website, your infringement of a third party’s right, including but not limited to the intellectual property rights. Despite aforementioned, you undertake to cooperate with our defence such as claims at your expense, we retain the right at your expense, to assume the exclusive defence and control of any matter for which you are obliged to indemnify us. When we learn of a claim, actions or proceeding that is covered by this indemnity, we shall make a good faith effort to let you know about it.
        </Text>

        <Heading as="h2" size="lg" mt={6} mb={3}>
            User Data
        </Heading>
        <Text>
            We will maintain certain data that you transmit to the Website for the purpose of managing the performance of the Website, as well as data relating to your use of the Website. You are entirely responsible for any information you transmit or that is related to any activity you have don on the website, even though we regularly perform back ups. You consent that we will not be responsible for any loss or corruption of data and you hereby waive any right of action against us arising from any such loss or corruption of such data.
        </Text>

        <Heading as="h2" size="lg" mt={6} mb={3}>
            Electronic Communication, Transactions and Signatures
        </Heading>
        <Text>
            Electronic communications include using the Website, emailing us, and filling out online forms. You agree that any agreements, notices, disclosures and other communication we provide you electronically through email and website, satisfy any legal requirement that they be in writing and you give your consent to receive such communications. You hereby consent to the electronic delivery of notices, policies and transactions records that are initiated or completed with us or through a website, as well as the use of electronic signatures, contracts, orders and other records. You hereby give up any rights or obligations under any statutes, rules, ordinances, or other laws in any jurisdiction that call for original signature or delivery or preservation of non-electronic records, or use of any method other than electronic means for payments or credit issuance. 
        </Text>

        <Heading as="h2" size="lg" mt={6} mb={3}>
            Miscellaneous
        </Heading>
        <Text>
            The entire agreement and comprehension between both businesses is embodied in these Terms of Service,  as well as any policies or operating guidelines we may post on our website in connection to the website. Any entitlements or terms within these Terms of Service that we do not fail to exercise or enforce will not be enforceable.
        </Text>
        <Text>
            As an acknowledgement of benefits or clauses. to the maximum extent allowed by law, these conditions of services are in effect all or any of our rights and responsibilities and be assigned  to another person at any moment. any harm, disruption, delay or omission caused by an event above our own ability to control shall not constitute our obligation or duty.  Any section or portion of a provision of these Terms of Service that is found to be illegal, void, or unenforceable is deemed severable from these Terms of Service and has no bearing on the legality or enforceability of any other provisions. These Terms of Service and your use of the Website do not establish a joint venture, partnership, employment, or agency relationship between you and us. You acknowledge that only since we droughted these Terms of Service, they won't be interpreted against us. You hereby give up any defenses you may have based on these Terms of Service's electronic format and the parties' failure to sign them.
        </Text>



        <Heading as="h2" size="lg" mt={6} mb={3}>
          Contact Us
        </Heading>
        <Text>
            If you have any questions regarding these Terms of Service or use of the Website, please contact us by email at: <Link fontWeight="semibold" href='mailto:support@aquilis.co'>support@aquilis.co</Link> or by using the contact details below:
        </Text>
        <Flex my={2} gap={2} flexDirection={"column"}>
        <Text fontWeight="semibold">Aquilis LLC.. 30 N Gould St, STE R, Sheridan, WY 82801, USA.</Text>
        <Link fontWeight="semibold" href="tel:+13074002284">Tel: +1 (307) 400-2284</Link>
        
        </Flex>
      </Box>
    </Container>
  );
};

export default TermsPage;
