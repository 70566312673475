import React from 'react';
import { Box, Text, Heading, Container, OrderedList, ListItem } from '@chakra-ui/react';

const SecurityPage = () => {
  return (
    <Container maxW="container.lg" py={6}>
      <Heading as="h1" size="xl" mb={4}>
        Aquilis’s Security
      </Heading>

      <Box>
        <Text>
          At Aquilis, data security comes first, whether it is for our clients or our own team member's data.
        </Text>
        <Text my={3}>
          We approach our clients with high-security methods, including technical and organizational control applied to each project. 
          The data protection procedure is stated below:
        </Text>

        <Heading as="h2" size="lg" mt={6} mb={3}>
          Data Classification
        </Heading>
        <Text>
          Our security teams manage all the data through classification for it to be used efficiently. This process 
          helps our specialists locate data effectively at Aquilis when it comes to risk management, compliance with 
          security standards, and data security.
        </Text>

        <Heading as="h2" size="lg" mt={6} mb={3}>
          Basic Principle
        </Heading>
        <Text>
          Regardless of all the security measures and other approval management, all our staff have access to only a 
          limited amount of data to complete their task. In case of a requirement for more information, the staff is 
          required to get approval from Corporate Security.
        </Text>

        <Heading as="h2" size="lg" mt={6} mb={3}>
          Backup Plans and Corporate Continuity Plan
        </Heading>
        <Text>
          To mitigate the effects of interruptions on company operations and to assist our organization in recovering from challenges, we implement continuous operation and risk mitigation strategies. Through their support, we optimize processes while reducing the probability of disasters while minimizing the risk of data loss and reputation damage. This brings us assurance that all the departments are processing efficiently without any breach.
        </Text>

        <Heading as="h2" size="lg" mt={6} mb={3}>
          Plan for Incident Response
        </Heading>
        <Text>
          At Aquilis, we ensure that our teams provide timely responses for incidents. Incidents can be detected by 
          our IPS/IDS and DLP systems or personal management. Each incident undergoes identification and resolution 
          and is documented to gain further experience and improvements for the future.
        </Text>

        <Heading as="h2" size="lg" mt={6} mb={3}>
          Staff Verification and Orientation Procedure
        </Heading>
        <Text>
          A candidate moves on to the onboarding phase after completing the interview phase. We verify each applicant’s 
          past job experience and conduct employment background checks to determine if the applicant has any criminal 
          record or past legal issues, which is critical when working on highly secure projects.
        </Text>

        <Heading as="h2" size="lg" mt={6} mb={3}>
          Security Management and Training Procedure
        </Heading>
        <Text>
            The members of Aquilis receive all the necessary security management training, education and regular updates for company policies and procedures. Our training program includes verification of all the learning materials and phishing simulations like scam/ spam/ fraud attempts. Team members are monitored through the scoreboard of the corporation's security.
        </Text>

        <Heading as="h2" size="lg" mt={6} mb={3}>
          Mechanism for Preventing and Detecting Intrusions
        </Heading>
        <Text>
          To monitor security breaches, we’ve implemented IDS/IPS. Featuring a separate graphical policy administrator and threat-detection abilities, this framework reveals the truth and stops illegal access to our corporate system.
        </Text>

        <Heading as="h2" size="lg" mt={6} mb={3}>
          Data Loss Prevention (DLP)
        </Heading>
        <Text>
           DPL solution prevents data leaks, by controlling all the device activity and a constant scan over each transmission to its endpoints to protect from any kind of suspicious links or viruses. Our DPL system ensures critical data such as client, partner, and employee information we control all unauthorized access while reporting all sensitive incidents.
        </Text>

        <Heading as="h2" size="lg" mt={6} mb={3}>
          Role-Based Access Control (RBAC) + MDM
        </Heading>
        <Text>
            Our RBAC system allows employees to have limited access to information that is required for them to complete their jobs to prevent unnecessary access to other sensitive information of the company. We monitor all the devices connected to our company including mobile phones, tablets, laptops, and other smart devices at work.
        </Text>

        <Heading as="h2" size="lg" mt={6} mb={3}>
          Multi-Factor Authentication (MFA)
        </Heading>
        <Text my={3}>
        We use MFA as a component of our security strategy.
        </Text>
        <Text my={3}>
        <b>Increased Security:</b> as compared to single-factor authentication we use double-factor authentication for our data security

        </Text>
        <Text my={3}>
        <b>Regulations Adherence:</b> multi-factor authentication helps businesses comply with industry rules.

        </Text>
        <Text my={3}>
        <b>Improved User Experience:</b> Clients will have a chance to better user experience when knowing all their data is being handled efficiently. Security and user experience are enhanced rather than being worried about weaker security
        </Text>

        <Heading as="h2" size="lg" mt={6} mb={3}>
          Password Security
        </Heading>
        <Text>
            Our company holds strict regulations regarding password security. At Aquilis we do our best to protect all the sensitive information from our users while making their experience greater.
            Our team follows the latest password policy management standards, including all the special characters from other languages, to ensure your protection.
        </Text>

        <Heading as="h2" size="lg" mt={6} mb={3}>
          Compliance Control through Internal Audits Process
        </Heading>
        <Text>
        Each department conducts an annual internal audit and risk assessment. The audit's purpose is to independently assess the effectiveness in our organization's internal surveillance, governance, and risk mitigation procedures.
        </Text>
        <Text my={3}>
            Internal audits are carried out through:
        </Text>
        <OrderedList spacing={3} py={4} px={8}>
          <ListItem>Assessing weaknesses in the system and security concerns.</ListItem>
          <ListItem>Establish a safety standard by which further audits can be reviewed.</ListItem>
          <ListItem>Implement the privacy guidelines adopted by the internal organization</ListItem>
          <ListItem>Follow the demands of external authorities.</ListItem>
          <ListItem>Assess the quality of security training</ListItem>
          <ListItem>Determine the required resources</ListItem>
        </OrderedList>
      </Box>
    </Container>
  );
};

export default SecurityPage;
