import React from 'react';
import { Box, Text, Heading, Container, OrderedList, ListItem, Link, Flex, UnorderedList } from '@chakra-ui/react';

const PrivacyPage = () => {
  return (
    <Container maxW="container.lg" py={6}>
      <Heading as="h1" size="xl" mb={4}>
        Privacy & Cookies Policy
      </Heading>
      <Text fontSize="sm" color="gray.500" mb={4}>
        Effective Date: 21 February, 2025
      </Text>

      <Box>
        <Heading as="h2" size="lg" mt={6} mb={3}>
          Introduction: Who we are
        </Heading>
        <Text>
          Aquilis ("we," "us," or "our") is committed to protecting your privacy. This privacy policy is in compliance
          with the applicable data protection law. We act as a data controller when processing your data.
        </Text>

        <Heading as="h6" size="md" mt={6} mb={3}>
          What is Personal Data?
        </Heading>
        <Text>
          Personal data refers to any information related to a person, including Name, Identification number, location,
          economic, psychological, mental, cultural, and social identity of a person.
        </Text>

        <Heading as="h2" size="lg" mt={6} mb={3}>
          Information We Collect
        </Heading>
        <Text>
        We collect personal information that is provided by you directly. We may store different types of data which are relevant and we do not collect any information which is irrelevant to the project. 
        <br/>The data which is collected are as following:
        </Text>
        <OrderedList spacing={3} py={4} px={8}>
          <ListItem>
            <b>Client’s Data:</b><br/> Name, title, email, phone number, company name, address and organization name. 
          </ListItem>
          <ListItem>
            <b>Transaction Data:</b><br/> Information regarding payments from your side and other related details of the purchased service from us. 
          </ListItem>
          <ListItem>
            <b>Financial Data:</b><br/> Information related to payment methods, bank accounts, credit card details, billing and invoices.
          </ListItem>
          <ListItem>
            <b>Contact Data:</b><br/> Information about your business, your business type and records created as a result of engagement with us through phone calls, internet contacts or emails. 
          </ListItem>
          <ListItem>
            <b>Technical Data:</b><br/> Information such as operating system and its interface, IP address, date and time of inquiry, access status/http status code, browser, website making the request, language, request content and browser software version. 
          </ListItem>
        </OrderedList>

        <Heading as="h2" size="lg" mt={6} mb={3}>
          Cookies
        </Heading>
        <Text my={3}>
            We and our third party partners may use many different technologies to gather and store the personal information which can be used to measure the effectiveness of our services, email campaigns and to provide analytical data.
        </Text>
        <Text my={3}>
            Cookies are small text files sent by us to your device, which helps us to improve the website. <br/>
            Persistent cookies last until you or the browser deletes them or it can last until it expires. Session based cookies only last until the browser is open. The cookies placed on the device can not damage your device or files. 
        </Text>
        <Text my={3}>
            Upon visiting our website, you will be informed about the cookies. If you are not interested in helping us improve, you can set up your preference via cookies preference center at any time. Many browsers let you manage cookies to suit you. You set up some rules to have more control over your privacy. This means you can disallow any cookies which you do not trust. 
        </Text>
        <Text my={3}>
            Browser manufacturers which provide help pages relating to cookie management in their products: Google Chrome, Internet Explorer, Mozilla Firefox, Safari, Microsoft Edge,Opera. For other browsers usage, you can consult with your browser manufacturer. If the cookies preferences are limited, it may worsen the experience as it will not be personalized. It may not save some information. 
        </Text>
        <Text my={3}>
            To find out more about cookies, visit this site:  <Link href='https://allaboutcookies.org/' color="blue.500" isExternal>https://allaboutcookies.org/</Link> 
        </Text>

        <Heading as="h2" size="lg" mt={6} mb={3}>
            How We Collect Personal Data
        </Heading>
        <Text>We collect Personal Data in the following ways:</Text>
        <UnorderedList spacing={3} py={4} px={8}>
          <ListItem>When you visit our website.</ListItem>
          <ListItem>When you fill in the form present on the website.</ListItem>
          <ListItem>When you contact us via email, phone or through any other means. </ListItem>
          <ListItem>We may receive your personal information when you are liking or following or interacting with our pages on social media platforms.</ListItem>
        </UnorderedList>


        <Heading as="h2" size="lg" mt={6} mb={3}>
        How We Use Personal Data
        </Heading>
        <Text>Your personal information is never disclosed, shared or sold without your consent, unless it is a requirement by law. The purpose for collecting information and processing is given below:</Text>
        <Heading as="h6" size="md" mt={2} mb={2}>
        Performance of contract:
        </Heading>
        <UnorderedList spacing={3} py={4} px={8}>
          <ListItem>To manage, administer and apply services which are included in the contract. </ListItem>
          <ListItem>To share personal Data with our service providers partners to provide services to you.</ListItem>
          <ListItem>To manage payments of fees and charges.  </ListItem>
          <ListItem>To inform you about changes in terms of services or use and privacy & cookies policy. </ListItem>
        </UnorderedList>

        <Heading as="h6" size="md" mt={2} mb={2}>
        Legitimate interest:
        </Heading>
        <UnorderedList spacing={3} py={4} px={8}>
          <ListItem>To analyze and improve our services, systems and processes. </ListItem>
          <ListItem>To train and check the quality of services. </ListItem>
          <ListItem>To remain compliant with legal and regulatory requirements.</ListItem>
          <ListItem>To monitor and record conversation between us.</ListItem>
          <ListItem>To research, statistical and market analysis. </ListItem>
          <ListItem>To send occasional marketing information. </ListItem>
          <ListItem>To share your personal data with people that support our business system and processes or to comply with legal or regulatory requirements. </ListItem>

        </UnorderedList>
      

        <Heading as="h2" size="lg" mt={6} mb={3}>
          Your Rights
        </Heading>
        <Text>You have rights in relation to the use of personal data. Under certain circumstances, you have following rights to:</Text>
        <Text mt={3}>
            <b>Request erasure:</b><br/> It commonly enables you to ask us to remove personal data where there is no good reason for us to continue to process it or where we may have processed your information unlawfully. We may not be able to erase your personal information upon your request for specific legal reasons and you will be informed about it upon your request.   
        </Text>
        <Text mt={3}>
            <b>Request access:</b><br/> It is also known as a ‘Data subject access request’. This enables you to receive a copy of personal data we have about you and check if we are lawfully processing it. 
        </Text>
        <Text mt={3}>
            <b>Request correction:</b><br/> This enables you to correct any incorrect information we hold about you. We may need 	to verify the accuracy of the new personal information. 
        </Text>
        <Text mt={3}>
            <b>Object to processing:</b><br/> This enables you to ask us to delete your personal data where you have Successfully exercised your right to object to our processing. 
        </Text>
        <Text mt={3}>
            <b>Right to objection:</b><br/> You have the right to object to the use of your personal data if we are relying on legitimate interest or third parties and there is something about the process which makes you want to object to processing because you believe it  violates your fundamental rights or freedom. You can also object on data usage for marketing. In rare circumstances, we may demonstrate how we have a valid reason to process your information which outweigh your rights and freedom. 
        </Text>
        <Text mt={3}>
            <b>Request restriction of processing:</b><br/> This enables you to request us to stop processing of your personal information under following circumstances. 
        </Text>
        <OrderedList spacing={3} py={4} px={8}>
          <ListItem>If you want to have data accuracy.</ListItem>
          <ListItem>If our use of data is unlawful, but you do not want us to erase it.</ListItem>
          <ListItem>If you need us to hold the data even when we no longer need it. </ListItem>
          <ListItem>If you have objected to our use of your data, we need to verify whether we have overriding legitimate grounds to use it.</ListItem>
        </OrderedList>

        <Text mt={3}>
            <b> Withdraw consent at any time:</b><br/>This is applicable only where we are relying on your consent to process your information. Any process which is conducted before the withdrawal will not effect its lawfulness. Some  services might not be given if your consent is withdrawn. We will let you know upon withdrawal. 
        </Text>
        <Text mt={3}>
        If you want to exercise any of these rights, you can contact us. If we get a request from you to exercise your rights, we may need to verify your identity before proceeding to make sure your data is protected.  
        </Text>


        <Heading as="h2" size="lg" mt={6} mb={3}>
            Sharing and Disclosing your personal data
        </Heading>
        <Text my={3}>
            Your personal information is not shared or disclosed with your consent, other than legal requirements. We do use third parties and affiliates to provide the services. However, we only share the data which is needed and used in accordance with instruction from us and the data protection laws or any other confidential security measures. 
        </Text>

        <Heading as="h2" size="lg" mt={6} mb={3}>
             Safe guardian Measures
        </Heading>
        <UnorderedList spacing={3} py={4} px={8}>
          <ListItem>We implement every reasonable precaution to protect and secure your personal data.</ListItem>
          <ListItem>We have security measures in place to prevent personal from being lost, used or accessed unauthorised ways.</ListItem>
          <ListItem>We have procedures in place to detect suspicious activities, data breach and we will notify you and any applicable regulator of a suspected breach where it is legally required to do so.</ListItem>
          <ListItem>When we share data with third parties, we do not allow the third parties to use the data for their own irrelevant purpose, they are only allowed to process your data for specified purposes instructed by us.</ListItem>
        </UnorderedList>
      

        <Heading as="h2" size="lg" mt={6} mb={3}>
            International Data Transfer
        </Heading>
        <Text my={3}>
            We may transfer the personal data outside of the USA. We will ensure that the personal data is protected by appropriate safeguards such as: 
        </Text>
        <Text my={3}>
            The destination country has been considered safe for the protection of data.
            The service provider is legally bound by specific contractual obligations required under data protection law.
        </Text>


        <Heading as="h2" size="lg" mt={6} mb={3}>
        Consequences of not providing your personal data
        </Heading>
        <Text>
        Providing personal information is not obligatory. However, information will be required in order to provide you our services or respond to your queries. We will nor be able to provide some services without information.
        </Text>

        <Heading as="h2" size="lg" mt={6} mb={3}>
            How long we keep your information
        </Heading>
        <Text>
            We only retain your personal information as long as it is necessary. While retaining information we consider:
        </Text>
        <UnorderedList spacing={3} py={4} px={8}>
          <ListItem>The duration of the task we collected information for.</ListItem>
          <ListItem>Fulfilling and managing our relationship with you.</ListItem>
          <ListItem>We retain personal information to satisfy legal, regulatory, accounting or reporting requirements.</ListItem>
        </UnorderedList>

        <Heading as="h2" size="lg" mt={6} mb={3}>
            Age Limitations
        </Heading>
        <Text>
            We do not allow use of our services for anyone younger than 18 years old. If you learn about anyone younger than 18 years old has unlawfully provided us information. You can let us know and we will delete it for you.         
        </Text>

        <Heading as="h2" size="lg" mt={6} mb={3}>
            Data Protection Authority
        </Heading>
        <Text>
        Subject to relevant legislation, you have the right to (i) restrict our use of your Personal Data and (ii) file a complaint with your local data protection authority.
        </Text>

        <Heading as="h2" size="lg" mt={6} mb={3}>
          Links to Other Websites
        </Heading>
        <Text>
            There might be links to other fascinating sites on our website, but please be cautious we don’t hold any control over those websites for your Privacy and Safety Policy standards because those websites are not covered by this Privacy & Cookies Policy, we are unable to guarantee your security or any private information you submit to them while visiting. You should be cautious about the relevant policies of that website.
        </Text>

        <Heading as="h6" size="md" mt={6} mb={3}>
          Questions And Concerns
        </Heading>
        <Text>
            You may contact our Data Protection Officer by email us, or by using the contact details below:
        </Text>
        <Flex my={2} flexDirection="column" gap={2}>
          <Text fontWeight="semibold">Aquilis LLC, 30 N Gould St, STE R, Sheridan, WY 82801, USA</Text>
          <Link href="tel:+13074002284" color="blue.500" fontWeight="semibold">
            Tel: +1 (307) 400-2284
          </Link>
        </Flex>

        <Heading as="h2" size="lg" mt={6} mb={3}>
          Changes to This Privacy & Cookies Policy
        </Heading>
        <Text>
        Updates to our cookies and privacy statement. The privacy and cookies policy is susceptible to modifications at any time. If regulations, legislation, and professional standards shift, we might be required to implement these changes or alter our business. To keep you updated, all the changes will be announced on this page and invite you to read our Privacy & Cookies Policy. In addition, we will be sending an email notice to notify you of the significant impact on your privacy rights. Please contact us in for further queries it will be assigned to Data Protection  officer if you disagree with the modification  made to this Privacy and Cookies Policy
        </Text>
      </Box>
    </Container>
  );
};

export default PrivacyPage;
